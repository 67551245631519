import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    agentList: [],
};

export const AgentManagerSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        getAgentList: (state, action) => {
            state.agentList = action.payload;
        },
    }
})

export const { getAgentList } = AgentManagerSlice.actions;

export const fetchAgents = (instance, accounts, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance, accounts);
        const result = await client.agents.get(criteria);        
        dispatch(getAgentList(result));
        return result;
    } catch (error) {
        console.log(error);
        
    }
}

export default AgentManagerSlice.reducer;